import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import BlocksRenderer from "../components/blocks-renderer";
import Seo from "../components/seo";
import { Link } from "gatsby";
const ArticlePage = ({ data }) => {
  const article = data.strapiArticle;
  // console.log(article);

  const [urlArticle, setUrlArticle] = useState("");

  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.cover,
  };

  useEffect(() => {
    setUrlArticle(window ? window.location.href : "");
  }, []);

  return (
    <Layout as="article">
      <Seo seo={seo} />

      <section class="ud-pt-[150px] ud-pb-[120px]">
        <div class="ud-container">
          <div class="ud-mx-[-16px] ud-flex ud-flex-wrap ud-justify-center">
            <div class="ud-w-full ud-px-4 lg:ud-w-8/12">
              <div>
                <h1
                  class="
              ud-mb-8 ud-text-3xl
              ud-font-bold
              ud-leading-tight
              ud-text-black
              dark:ud-text-white
              sm:ud-text-4xl
              sm:ud-leading-tight
            "
                >
                  {article.title}
                </h1>
                <div
                  class="
              ud-mb-10
              ud-flex
              ud-flex-wrap
              ud-items-center
              ud-justify-between
              ud-border-b
              ud-border-body-color
              ud-border-opacity-10
              ud-pb-4
              dark:ud-border-white dark:ud-border-opacity-10
            "
                >
                  <div class="ud-flex ud-flex-wrap ud-items-center">
                    <div class="ud-mr-10 ud-mb-5 ud-flex ud-items-center">
                      <div
                        class="
                    ud-mr-4
                    ud-h-[40px]
                    ud-w-full
                    ud-max-w-[40px]
                    ud-overflow-hidden
                    ud-rounded-full
                  "
                      >
                        <GatsbyImage
                          image={getImage(article.author.avatar?.localFile)}
                          alt={article.author.avatar?.alternativeText}
                          class="ud-w-full"
                        />
                      </div>
                      <div class="ud-w-full">
                        <h4
                          class="
                      ud-mb-1 ud-text-base ud-font-medium ud-text-body-color
                    "
                        >
                          {article.author.name}
                        </h4>
                      </div>
                    </div>
                    <div class="ud-mb-5 ud-flex ud-items-center">
                      <p
                        class="
                    ud-mr-5
                    ud-flex
                    ud-items-center
                    ud-text-base
                    ud-font-medium
                    ud-text-body-color
                  "
                      >
                        <span class="ud-mr-3">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            class="ud-fill-current"
                          >
                            <path d="M3.89531 8.67529H3.10666C2.96327 8.67529 2.86768 8.77089 2.86768 8.91428V9.67904C2.86768 9.82243 2.96327 9.91802 3.10666 9.91802H3.89531C4.03871 9.91802 4.1343 9.82243 4.1343 9.67904V8.91428C4.1343 8.77089 4.03871 8.67529 3.89531 8.67529Z" />
                            <path d="M6.429 8.67529H5.64035C5.49696 8.67529 5.40137 8.77089 5.40137 8.91428V9.67904C5.40137 9.82243 5.49696 9.91802 5.64035 9.91802H6.429C6.57239 9.91802 6.66799 9.82243 6.66799 9.67904V8.91428C6.66799 8.77089 6.5485 8.67529 6.429 8.67529Z" />
                            <path d="M8.93828 8.67529H8.14963C8.00624 8.67529 7.91064 8.77089 7.91064 8.91428V9.67904C7.91064 9.82243 8.00624 9.91802 8.14963 9.91802H8.93828C9.08167 9.91802 9.17727 9.82243 9.17727 9.67904V8.91428C9.17727 8.77089 9.08167 8.67529 8.93828 8.67529Z" />
                            <path d="M11.4715 8.67529H10.6828C10.5394 8.67529 10.4438 8.77089 10.4438 8.91428V9.67904C10.4438 9.82243 10.5394 9.91802 10.6828 9.91802H11.4715C11.6149 9.91802 11.7105 9.82243 11.7105 9.67904V8.91428C11.7105 8.77089 11.591 8.67529 11.4715 8.67529Z" />
                            <path d="M3.89531 11.1606H3.10666C2.96327 11.1606 2.86768 11.2562 2.86768 11.3996V12.1644C2.86768 12.3078 2.96327 12.4034 3.10666 12.4034H3.89531C4.03871 12.4034 4.1343 12.3078 4.1343 12.1644V11.3996C4.1343 11.2562 4.03871 11.1606 3.89531 11.1606Z" />
                            <path d="M6.429 11.1606H5.64035C5.49696 11.1606 5.40137 11.2562 5.40137 11.3996V12.1644C5.40137 12.3078 5.49696 12.4034 5.64035 12.4034H6.429C6.57239 12.4034 6.66799 12.3078 6.66799 12.1644V11.3996C6.66799 11.2562 6.5485 11.1606 6.429 11.1606Z" />
                            <path d="M8.93828 11.1606H8.14963C8.00624 11.1606 7.91064 11.2562 7.91064 11.3996V12.1644C7.91064 12.3078 8.00624 12.4034 8.14963 12.4034H8.93828C9.08167 12.4034 9.17727 12.3078 9.17727 12.1644V11.3996C9.17727 11.2562 9.08167 11.1606 8.93828 11.1606Z" />
                            <path d="M11.4715 11.1606H10.6828C10.5394 11.1606 10.4438 11.2562 10.4438 11.3996V12.1644C10.4438 12.3078 10.5394 12.4034 10.6828 12.4034H11.4715C11.6149 12.4034 11.7105 12.3078 11.7105 12.1644V11.3996C11.7105 11.2562 11.591 11.1606 11.4715 11.1606Z" />
                            <path d="M13.2637 3.3697H7.64754V2.58105C8.19721 2.43765 8.62738 1.91189 8.62738 1.31442C8.62738 0.597464 8.02992 0 7.28906 0C6.54821 0 5.95074 0.597464 5.95074 1.31442C5.95074 1.91189 6.35702 2.41376 6.93058 2.58105V3.3697H1.31442C0.597464 3.3697 0 3.96716 0 4.68412V13.2637C0 13.9807 0.597464 14.5781 1.31442 14.5781H13.2637C13.9807 14.5781 14.5781 13.9807 14.5781 13.2637V4.68412C14.5781 3.96716 13.9807 3.3697 13.2637 3.3697ZM6.6677 1.31442C6.6677 0.979841 6.93058 0.716957 7.28906 0.716957C7.62364 0.716957 7.91042 0.979841 7.91042 1.31442C7.91042 1.649 7.64754 1.91189 7.28906 1.91189C6.95448 1.91189 6.6677 1.6251 6.6677 1.31442ZM1.31442 4.08665H13.2637C13.5983 4.08665 13.8612 4.34954 13.8612 4.68412V6.45261H0.716957V4.68412C0.716957 4.34954 0.979841 4.08665 1.31442 4.08665ZM13.2637 13.8612H1.31442C0.979841 13.8612 0.716957 13.5983 0.716957 13.2637V7.16957H13.8612V13.2637C13.8612 13.5983 13.5983 13.8612 13.2637 13.8612Z" />
                          </svg>
                        </span>
                        {article.publishedAt}
                      </p>
                    </div>
                  </div>
                  <div class="ud-mb-5">
                    <span
                      class="
                  ud-inline-flex
                  ud-items-center
                  ud-justify-center
                  ud-rounded-full
                  ud-bg-primary
                  ud-py-2
                  ud-px-4
                  ud-text-sm
                  ud-font-semibold
                  ud-text-white
                "
                    >
                      {article.category.name}
                    </span>
                  </div>
                </div>
                <div>
                  <h2
                    class="
                ud-mb-10  ud-font-medium
                ud-leading-relaxed
                ud-text-body-color
                sm:ud-text-xl
                sm:ud-leading-relaxed
               
                lg:ud-leading-relaxed
                xl:ud-text-xl xl:ud-leading-relaxed
              "
                  >
                    {article.description}
                  </h2>

                  <div class="ud-mb-10 ud-w-full ud-overflow-hidden ud-rounded">
                    <GatsbyImage
                      image={getImage(article?.cover?.localFile)}
                      alt={article?.cover?.alternativeText}
                      className="ud-h-full ud-w-full ud-object-cover ud-object-center"
                    />
                  </div>
                  <main className="mt-8">
                    <BlocksRenderer blocks={article.blocks || []} />
                  </main>

                  <div
                    class="
                    mt-4
                ud-relative ud-z-10 ud-mb-10
                ud-overflow-hidden
                ud-rounded-md
                ud-bg-primary
                ud-bg-opacity-10 ud-p-8 md:ud-p-9 lg:ud-p-8 xl:ud-p-9
              "
                  >
                    <p
                      class="
                  ud-mb-8
                  ud-text-center
                  ud-text-base
                  ud-font-medium
                  ud-italic
                  ud-text-body-color
                "
                    >
                      <strong> Vill du testa att tjäna pengar på Zwop</strong> -
                      genom handel med affärsinformation? Prova Zwop helt
                      gratis!
                    </p>
                    <div className="ud-flex ud-items-center ud-justify-center">
                      <Link
                        className="
                      ud-mx-2
                      ud-rounded-full
                      ud-bg-primary
                      ud-py-4
                      ud-px-8
                      ud-text-base
                      ud-font-semibold
                      ud-text-white
                      ud-transition
                      ud-duration-300
                      ud-ease-in-out
                      hover:ud-bg-opacity-80
                    "
                        to={`${process.env.APP_URL}/signup`}
                        target="_blank"
                      >
                        Skapa konto
                      </Link>
                    </div>
                    <span class="ud-absolute ud-left-0 ud-top-0 ud-z-[-1]">
                      <svg
                        width="132"
                        height="109"
                        viewBox="0 0 132 109"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.5"
                          d="M33.0354 90.11C19.9851 102.723 -3.75916 101.834 -14 99.8125V-15H132C131.456 -12.4396 127.759 -2.95278 117.318 14.5117C104.268 36.3422 78.7114 31.8952 63.2141 41.1934C47.7169 50.4916 49.3482 74.3435 33.0354 90.11Z"
                          fill="url(#paint0_linear_111:606)"
                        />
                        <path
                          opacity="0.5"
                          d="M33.3654 85.0768C24.1476 98.7862 1.19876 106.079 -9.12343 108.011L-38.876 22.9988L100.816 -25.8905C100.959 -23.8126 99.8798 -15.5499 94.4164 0.87754C87.5871 21.4119 61.9822 26.677 49.5641 38.7512C37.146 50.8253 44.8877 67.9401 33.3654 85.0768Z"
                          fill="url(#paint1_linear_111:606)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_111:606"
                            x1="94.7523"
                            y1="82.0246"
                            x2="8.40951"
                            y2="52.0609"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="white" stop-opacity="0.06" />
                            <stop
                              offset="1"
                              stop-color="white"
                              stop-opacity="0"
                            />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_111:606"
                            x1="90.3206"
                            y1="58.4236"
                            x2="1.16149"
                            y2="50.8365"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="white" stop-opacity="0.06" />
                            <stop
                              offset="1"
                              stop-color="white"
                              stop-opacity="0"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    <span class="ud-absolute ud-right-0 ud-bottom-0 ud-z-[-1]">
                      <svg
                        width="53"
                        height="30"
                        viewBox="0 0 53 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          opacity="0.8"
                          cx="37.5"
                          cy="37.5"
                          r="37.5"
                          fill="#4A6CF7"
                        />
                        <mask
                          id="mask0_111:596"
                          style={{ "mask-type": "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="75"
                          height="75"
                        >
                          <circle
                            opacity="0.8"
                            cx="37.5"
                            cy="37.5"
                            r="37.5"
                            fill="#4A6CF7"
                          />
                        </mask>
                        <g mask="url(#mask0_111:596)">
                          <circle
                            opacity="0.8"
                            cx="37.5"
                            cy="37.5"
                            r="37.5"
                            fill="url(#paint0_radial_111:596)"
                          />
                          <g opacity="0.8" filter="url(#filter0_f_111:596)">
                            <circle
                              cx="40.8089"
                              cy="19.853"
                              r="15.4412"
                              fill="white"
                            />
                          </g>
                        </g>
                        <defs>
                          <filter
                            id="filter0_f_111:596"
                            x="4.36768"
                            y="-16.5881"
                            width="72.8823"
                            height="72.8823"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="BackgroundImageFix"
                              result="shape"
                            />
                            <feGaussianBlur
                              stdDeviation="10.5"
                              result="effect1_foregroundBlur_111:596"
                            />
                          </filter>
                          <radialGradient
                            id="paint0_radial_111:596"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(37.5 37.5) rotate(90) scale(40.2574)"
                          >
                            <stop stop-opacity="0.47" />
                            <stop offset="1" stop-opacity="0" />
                          </radialGradient>
                        </defs>
                      </svg>
                    </span>
                  </div>

                  <div class="ud-items-center ud-justify-between sm:ud-flex">
                    <div class="ud-mb-5">
                      <h5
                        class="
                    ud-mb-3 ud-text-sm ud-font-medium
                    ud-text-body-color
                    
                  "
                      >
                        Dela artikeln :
                      </h5>
                      <div class="ud-flex ud-items-center ">
                        <Link
                          className="
                          ud-inline-flex
                          ud-h-9
                          ud-w-9
                          ud-items-center
                          ud-justify-center
                          ud-rounded-md
                          ud-bg-primary
                          ud-bg-opacity-10
                          ud-text-body-color
                          hover:ud-bg-opacity-100
                          hover:ud-text-white sm:ud-mr-3
                    "
                          to={`https://www.linkedin.com/sharing/share-offsite/?url=${urlArticle}`}
                          target="_blank"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            class="ud-fill-current"
                          >
                            <path d="M14.3442 0H1.12455C0.499798 0 0 0.497491 0 1.11936V14.3029C0 14.8999 0.499798 15.4222 1.12455 15.4222H14.2942C14.919 15.4222 15.4188 14.9247 15.4188 14.3029V1.09448C15.4688 0.497491 14.969 0 14.3442 0ZM4.57316 13.1089H2.29907V5.7709H4.57316V13.1089ZM3.42362 4.75104C2.67392 4.75104 2.09915 4.15405 2.09915 3.43269C2.09915 2.71133 2.69891 2.11434 3.42362 2.11434C4.14833 2.11434 4.74809 2.71133 4.74809 3.43269C4.74809 4.15405 4.19831 4.75104 3.42362 4.75104ZM13.1947 13.1089H10.9206V9.55183C10.9206 8.7061 10.8956 7.58674 9.72108 7.58674C8.52156 7.58674 8.34663 8.53198 8.34663 9.47721V13.1089H6.07255V5.7709H8.29665V6.79076H8.32164C8.64651 6.19377 9.37122 5.59678 10.4958 5.59678C12.8198 5.59678 13.2447 7.08925 13.2447 9.12897V13.1089H13.1947Z" />
                          </svg>
                        </Link>
                        <Link
                          className="
                          ud-inline-flex
                          ud-h-9
                          ud-w-9
                          ud-items-center
                          ud-justify-center
                          ud-rounded-md
                          ud-bg-primary
                          ud-bg-opacity-10
                          ud-text-body-color
                          hover:ud-bg-opacity-100
                          hover:ud-text-white sm:ud-mr-3
                    "
                          to={`https://twitter.com/intent/tweet?url=${urlArticle}&text=${article.description}`}
                          target="_blank"
                        >
                          <svg
                            width="18"
                            height="14"
                            viewBox="0 0 18 14"
                            class="ud-fill-current"
                          >
                            <path d="M15.5524 2.26027L16.625 1.0274C16.9355 0.693493 17.0202 0.436644 17.0484 0.308219C16.2016 0.770548 15.4113 0.924658 14.9032 0.924658H14.7056L14.5927 0.821918C13.9153 0.282534 13.0685 0 12.1653 0C10.1895 0 8.6371 1.48973 8.6371 3.21062C8.6371 3.31336 8.6371 3.46747 8.66532 3.57021L8.75 4.0839L8.15726 4.05822C4.54435 3.95548 1.58065 1.13014 1.10081 0.642123C0.310484 1.92637 0.762097 3.15925 1.24194 3.92979L2.20161 5.36815L0.677419 4.5976C0.705645 5.67637 1.15726 6.52397 2.03226 7.14041L2.79435 7.65411L2.03226 7.93665C2.5121 9.24658 3.58468 9.78596 4.375 9.99144L5.41935 10.2483L4.43145 10.8647C2.85081 11.8921 0.875 11.8151 0 11.738C1.77823 12.8682 3.89516 13.125 5.3629 13.125C6.46371 13.125 7.28226 13.0223 7.47984 12.9452C15.3831 11.25 15.75 4.82877 15.75 3.54452V3.36473L15.9194 3.26199C16.879 2.44007 17.2742 2.00342 17.5 1.74658C17.4153 1.77226 17.3024 1.82363 17.1895 1.84932L15.5524 2.26027Z" />
                          </svg>
                        </Link>

                        <Link
                          className="
                          ud-inline-flex
                          ud-h-9
                          ud-w-9
                          ud-items-center
                          ud-justify-center
                          ud-rounded-md
                          ud-bg-primary
                          ud-bg-opacity-10
                          ud-text-body-color
                          hover:ud-bg-opacity-100
                          hover:ud-text-white sm:ud-mr-3
                    "
                          to={`https://www.facebook.com/sharer/sharer.php?u=#${urlArticle}`}
                          target="_blank"
                        >
                          <svg
                            width="9"
                            height="18"
                            viewBox="0 0 9 18"
                            class="ud-fill-current"
                          >
                            <path d="M8.13643 7H6.78036H6.29605V6.43548V4.68548V4.12097H6.78036H7.79741C8.06378 4.12097 8.28172 3.89516 8.28172 3.55645V0.564516C8.28172 0.254032 8.088 0 7.79741 0H6.02968C4.11665 0 2.78479 1.58064 2.78479 3.92339V6.37903V6.94355H2.30048H0.65382C0.314802 6.94355 0 7.25403 0 7.70564V9.7379C0 10.1331 0.266371 10.5 0.65382 10.5H2.25205H2.73636V11.0645V16.7379C2.73636 17.1331 3.00273 17.5 3.39018 17.5H5.66644C5.81174 17.5 5.93281 17.4153 6.02968 17.3024C6.12654 17.1895 6.19919 16.9919 6.19919 16.8226V11.0927V10.5282H6.70771H7.79741C8.11222 10.5282 8.35437 10.3024 8.4028 9.96371V9.93548V9.90726L8.74182 7.95968C8.76604 7.7621 8.74182 7.53629 8.59653 7.31048C8.54809 7.16935 8.33016 7.02823 8.13643 7Z" />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      description
      publishedAt(formatString: "YYYY-MM-DD", locale: "sv-SE")
      author {
        name
        role
        avatar {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1)
            }
          }
        }
      }
      category {
        name
      }
      blocks {
        ...Blocks
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default ArticlePage;
